import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

class BgPrimary extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
    this.body = document.querySelector('body');
    this.navTrigger = document.querySelector('.navigation--trigger');
    this.navTriggerCenter = null;
    this.scrollTriggerId = 'st-' + (Math.random() + 1).toString(36).substring(5);
    this.getNavTriggerCenter();

    this.width = window.innerWidth;

    window.addEventListener('resize', () => {
      if (this.width != window.innerWidth) {
        this.getNavTriggerCenter();
        ScrollTrigger.refresh();
        this.width = window.innerWidth
      }
    });

    this.scrollTrigger = ScrollTrigger.create({
      trigger: this,
      id: this.scrollTriggerId,
      start: 'top ' + this.navTriggerCenter,
      end: 'bottom ' + this.navTriggerCenter,
      onEnter: () => {
        this.navTrigger.classList.add('navigation__inverted')
      },
      onEnterBack: () => {
        this.navTrigger.classList.add('navigation__inverted')
      },
      onLeave: () => {
        this.navTrigger.classList.remove('navigation__inverted')
      },
      onLeaveBack: () => {
        this.navTrigger.classList.remove('navigation__inverted')
      },
    });
  }

  disconnectedCallback() {
    ScrollTrigger.getById(this.scrollTriggerId).kill();
  }


  getNavTriggerCenter() {
    const rect = this.navTrigger.getBoundingClientRect();
    this.navTriggerCenter = rect.top + (rect.height / 2) + 'px';
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('bg-primary', BgPrimary);
}
